@use 'abstracts' as *;

cad-icon {
  div {
    display: flex;

    mat-icon {
      pointer-events: none !important;

      &.icon__component--color-gray {
        color: clr(grayscale-600);
      }

      &.icon__component--color-gray-lighter {
        color: clr(grayscale-400);
      }

      &.icon__component--xsmall {
        @include icon-xsmall-size();
      }

      &.icon__component--small {
        @include icon-small-size();
      }

      &.icon__component--medium {
        @include icon-medium-size();
      }

      &.icon__component--large {
        @include icon-large-size();
      }

      @include align--center();
    }
  }
}
