@use 'abstracts' as *;

$scrollbar-row-height: 12px;

cad-scrollbar-row-next {
  height: $scrollbar-row-height;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-top: -$scrollbar-row-height;
  z-index: 11;

  @include flex--row();

  ::-webkit-scrollbar {
    height: size(8);
  }

  ::-webkit-scrollbar-thumb {
    border: none;
  }

  .scrollbar-cell--empty {
    flex-grow: 1;
  }

  .table-cell {
    flex-shrink: 0;
  }

  .table-cell:not(:last-child) {
    border: none;
  }

  cad-table-group-sections {
    .group-section {
      flex-direction: row;

      &.group-section--scrollable {
        transform: translateY(5px);
      }
    }

    &.group-sections--shifted {
      .group-section--scrollable {
        transform: translateY(-42px);
      }
    }
  }
}
