@use 'abstracts' as *;

cad-input-date-filter-control {
  cad-input-date {
    max-width: 10.5rem;
    padding: 0;
    border: 1px solid clr(grayscale-200);
    height: size(32);
    border-radius: $border-radius;
  }
  input {
    &:focus-visible {
      outline: none;
    }
    &:focus,
    &:hover {
      border: none !important;
    }
  }
}
