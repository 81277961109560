@use 'abstracts' as *;

cad-header-row-next {
  height: 3rem;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  line-height: 3rem;

  @include flex--row();
  .top-border-none {
    border-top: none;
  }
  .right-border-none {
    border-right: none;
  }
  .bottom-border-none {
    border-bottom: none;
  }

  cad-table-group-sections {
    .group-section {
      flex-direction: row;
    }
  }
}
