@use 'abstracts' as *;

cad-tree-next {
  display: block;

  cdk-tree {
    display: flex;
    flex-direction: column;
  }

  * {
    box-sizing: border-box;
  }
}

cad-tree-next .tree-node-container,
.tree-node-next-drag-preview {
  display: flex;
  align-items: center;

  .tree-node-next-toggle-button {
    background: transparent;
    border: none;
    padding: 0;
  }

  .tree-node-toggle-icon {
    display: block;
  }

  .tree-node-next-toggle-spacer {
    width: size(16);
  }

  .tree-node-next-content {
    min-width: 0;
    padding-inline-start: size(8);

    @include text-truncate();
  }
}
