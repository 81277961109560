//TODO Taras Vynnychuk 24/01/2023: refactor variables
.table-control-search-menu {
  .search__row {
    height: 3rem;
    padding: 0 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    background: white;

    .search__input {
      height: 2rem;
      border: 1px solid var(--color-grayscale-400);
      border-radius: 0.25rem;
      padding: 0 0.5rem;
    }
  }
}
