@use 'abstracts' as *;

.table-properties-panel {
  .table-properties-panel-list:not(.cdk-drop-list-dragging) {
    .table-properties-panel__menu-item {
      &:not(.table-properties-panel__menu-item--disabled):hover {
        background: clr(white-20);
      }
    }
  }

  .table-properties-panel__menu-item--disabled {
    color: clr(grayscale-400);
  }

  .table-properties-panel__menu-item-drag-placeholder {
    height: size(48);
    background: clr(grayscale-200);
  }
}

.table-properties-panel-list {
  max-height: 100vh;
  overflow: auto;
}

.table-properties-panel .table-properties-panel__menu-item,
.table-properties-panel__menu-item.cdk-drag-preview {
  display: flex;
  align-items: center;
  column-gap: size(8);
  width: 100%;
  height: size(48);
  padding-inline: size(16);
  box-sizing: border-box;

  .menu-item-property__text {
    flex-grow: 1;
    min-width: 8.5rem;
  }

  .menu-item__handle {
    text-align: center;
    color: clr(black-80);
    cursor: grab;

    &.locked {
      color: clr(grayscale-400);
      cursor: not-allowed;
    }
  }
}

.table-properties-panel__menu-item.cdk-drag-preview {
  background: white;
  outline: 1px dotted clr(green-40);
  cursor: move;
  filter: drop-shadow(0 0 size(8) clr(grayscale-400));
  pointer-events: none;
}
