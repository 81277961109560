@use 'abstracts' as *;

cad-toggle {
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
    margin: 0;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 32px;
    height: 1rem;

    background: clr(grayscale-600);
    background-image: url('../../assets/icons/uncheck-switcher.svg');
    background-repeat: no-repeat;
    background-position: 19px;

    display: block;
    border-radius: 14px;
    position: relative;

    &.disabled {
      background: clr(grayscale-200);
      background-image: url('../../assets/icons/uncheck-switcher.svg');
      background-repeat: no-repeat;
      background-position: 19px;
    }
  }

  label.disabled {
    cursor: default;
  }

  label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + label {
    background: clr(green-100);
    background-image: url('../../assets/icons/check-switcher.svg');
    background-repeat: no-repeat;
    background-position: 4px;

    &.disabled {
      background: clr(grayscale-200);
      background-image: url('../../assets/icons/check-switcher.svg');
      background-repeat: no-repeat;
      background-position: 4px;
    }
  }

  input:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  input:checked + label:active:after {
    transform: translateX(-100%) scaleX(1.2);
  }

  label:active:after {
    transform: scaleX(1.2);
    transform-origin: center center;
  }
}
