@use 'abstracts' as *;

cad-loader {
  height: 40px;

  svg {
    path#large-outer {
      fill: clr(primary-800);
    }

    path#small-inner {
      fill: clr(primary-700);
    }
  }
}
