@use 'abstracts' as *;

cad-table-group-sections {
  --shadow-color: #{clr(grayscale-300)};
  --shadow-color-none: transparent;
  --shadow-left-color: var(--shadow-color-none);
  --shadow-right-color: var(--shadow-color-none);

  display: flex;

  .group-section:not(.group-section--scrollable) {
    position: relative;

    &:before {
      --shadow-width: 6px;
      --shadow-offset: calc(var(--shadow-width) * -1);

      content: '';
      position: absolute;
      height: 100%;
      width: var(--shadow-width);
      background: linear-gradient(90deg, var(--shadow-color) 0%, transparent 100%);
      z-index: 1;
      pointer-events: none;
    }

    &:first-child {
      --shadow-color: var(--shadow-left-color);

      &:before {
        right: var(--shadow-offset);
      }
    }

    &:last-child {
      --shadow-color: var(--shadow-right-color);

      &:before {
        left: var(--shadow-offset);
        transform: rotateZ(180deg);
      }
    }
  }

  .group-section {
    display: flex;
    flex-direction: column;
  }

  .group-section--scrollable {
    overflow-x: auto;
  }
}
