@use 'abstracts' as *;

cad-refresh-login-dialog.mat-mdc-dialog-component-host {
  display: block;
  padding-inline: 1.5rem;

  .title {
    font-family: $ff-company !important;
    font-size: $fs-Medium !important;
    line-height: $lh-2XLarge !important;
    font-weight: $Bold !important;
  }

  .mat-dialog-actions,
  .actions {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    gap: 0.5rem;

    cad-button {
      &:not(:first-child) {
        margin-left: 0.5rem !important;
      }
    }
  }
}
