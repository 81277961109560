@use 'abstracts' as *;

cad-data-row-next {
  --background-color: transparent;

  user-select: none; /* CSS3 (little to no support) */
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: none; /* Gecko (Firefox) */
  -webkit-user-select: none; /* Webkit (Safari, Chrome) */
  height: 3rem;
  width: 100%;
  box-sizing: border-box;

  @include flex--row();

  &.hovered {
    --background-color: #{clr(grayscale-100)};
    .border-hover-top-bottom {
      border-bottom: solid 1px var(--color-grayscale-200);
      border-top: solid 1px var(--color-grayscale-200);
    }
  }
  .top-border-none {
    border-top: none;
  }
  .right-border-none {
    border-right: none;
  }
  .bottom-border-none {
    border-bottom: none;
  }
  &.selected {
    --background-color: #{clr(grayscale-100)};
  }

  &.status-warning {
    &.selected {
      --background-color: #{clr(warning-50)};
    }
  }

  &.status-success {
    &.selected {
      --background-color: #{clr(primary-50)};
    }
  }

  &.status-locked {
    &.selected {
      --background-color: #{clr(grayscale-100)};
    }
  }
}
