@use 'abstracts' as *;

cad-menu-item-sort-next {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 3rem;
  box-sizing: border-box;

  .menu-item-sort__icon {
    padding-right: 0.5rem;
  }

  .menu-item-sort__attribute {
    width: 10rem;
    margin: 0 0.5rem 0 0;
    box-sizing: border-box;
  }

  .menu-item-sort__direction {
    width: 8rem;
    margin: 0 0.5rem;
    box-sizing: border-box;
  }

  .menu-item-sort--active {
    padding: 0 1rem;
  }

  .menu-item-sort__attribute,
  .menu-item-sort__direction {
    border-radius: 0.25rem;
    border: 1px solid clr(grayscale-200);
  }

  &:hover {
    .menu-item-sort__attribute,
    .menu-item-sort__direction {
      border: 1px solid clr(grayscale-400);
    }
  }
}
