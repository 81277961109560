@use 'abstracts' as *;

button[cadButton] {
  all: unset;

  height: size(48);
  min-width: size(48);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  --button-color-main: #{clr(green-100)};
  --button-color-lighter: #{clr(green-20)};
  --button-color-darker: #{clr(green-100-darker)};

  &.warning {
    --button-color-main: #{clr(warning-500)};
    --button-color-lighter: #{clr(warning-50)};
    --button-color-darker: #{clr(warning-500)};
  }

  &.error {
    --button-color-main: #{clr(error-500)};
    --button-color-lighter: #{clr(error-50)};
    --button-color-darker: #{clr(error-500)};
  }

  &.locked {
    --button-color-main: #{clr(grayscale-500)};
    --button-color-lighter: #{clr(grayscale-50)};
    --button-color-darker: #{clr(grayscale-500)};
  }

  &.grayed {
    --button-color-main: #{clr(grayscale-700)};
    --button-color-lighter: #{clr(grayscale-200)};
    --button-color-darker: #{clr(grayscale-700)};
  }

  cursor: pointer;

  &[disabled] {
    --button-color-main: #{clr(grayscale-200)};
    --button-color-darker: #{clr(grayscale-500)};
    cursor: not-allowed;
  }

  &:not([disabled]) {
    .outlined {
      &:hover {
        .options-icon,
        & {
          background: var(--button-color-lighter);
          color: var(--button-color-darker);
        }
      }
    }
  }

  .button {
    height: 2rem;
    min-width: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: $border-radius;
    box-sizing: border-box;

    .main-element {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 0 size(8);
      border-radius: inherit !important;

      gap: size(8);

      &:not(:last-child) {
        border-radius: $border-radius 0 0 $border-radius;
      }
    }

    .options-icon {
      border-radius: 0 $border-radius $border-radius 0;
      padding-left: size(4);
      padding-right: size(4);
    }

    // CONTAINED
    &.contained {
      background: var(--button-color-main);
      color: white;

      .main-element:hover,
      .options-icon:hover {
        background: var(--button-color-darker);
      }

      .options-icon {
        border-left: 1px solid var(--button-color-lighter);
      }

      &.disabled {
        &.contained {
          cursor: not-allowed;
          color: var(--button-color-darker);
          background: var(--button-color-main);

          .main-element:hover,
          .options-icon:hover {
            color: var(--button-color-darker);
            background: var(--button-color-main);
          }
        }
      }
    }

    // OUTLINED
    &.outlined {
      border: 2px solid var(--button-color-main);
      color: var(--button-color-main);

      .options-icon {
        border-left: 1px solid var(--button-color-lighter);
      }
    }

    // TEXT
    &.text {
      color: var(--button-color-main);

      .main-element:hover,
      .options-icon:hover {
        background: var(--button-color-lighter);
        color: var(--button-color-darker);
      }

      .options-icon {
        border-left: 1px solid var(--button-color-lighter);
      }
    }

    &.disabled {
      &.text {
        cursor: not-allowed;

        .main-element:hover,
        .options-icon:hover {
          background: none;
          color: var(--button-color-main);
        }
      }
    }

    .leading-icon,
    .options-icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .material-icons {
      font-size: 1rem;
    }

    .text {
      @include font--button();
      height: 100%;
      line-height: 100%;

      white-space: nowrap;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
