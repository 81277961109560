@use 'abstracts' as *;

cad-sort-items .menu-item,
.cdk-drag-menu-item-preview {
  &:hover {
    background: clr(white-20);
  }

  &,
  cad-table-control-sort-next {
    height: 3rem;
    display: flex;
    align-items: center;
    min-width: 10rem;
  }

  .menu-item__handle {
    width: 3rem;
    line-height: 3rem;
    text-align: center;
    color: clr(black-80);
    cursor: grab;

    &.locked {
      color: clr(grayscale-400);
      cursor: not-allowed;
    }
  }
}

.cdk-drag-menu-item-preview {
  cursor: move;
  background-color: clr(white);
  filter: drop-shadow(0 0 0.5rem clr(grayscale-400));
}

.cdk-drag-menu-item-placeholder {
  height: 3rem;
  background: clr(primary-50);
}
