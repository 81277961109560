@use 'abstracts' as *;

cad-input-number-next {
  --min-width: #{size(72)};
  --padding-inline: #{size(4)};

  position: relative;
  display: flex;
  align-items: center;
  min-width: var(--min-width);
  max-width: 16rem;

  .input-number-container {
    width: 100%;
    min-width: var(--min-width);
  }

  .input-number-container--size-small {
    height: size(24);
  }

  .input-number-container--size-medium {
    height: size(32);
  }

  .input-number__text {
    display: flex;
    height: 100%;
    width: 100%;
    min-width: 4ch;
    align-items: center;
    justify-content: var(--justify-content, flex-start);
    padding-inline: var(--padding-inline, 0);
    border: 1px solid transparent;
    box-sizing: border-box;
  }

  .input-number__text,
  .input-number__text-content {
    @include text-truncate();
  }

  .input-number__text-content-placeholder {
    font-style: italic;
    cursor: pointer;
  }

  &.input-number--readonly .input-number__text-content-placeholder {
    cursor: default;
  }

  .input-number__text {
    [aria-hidden='true'] {
      display: none;
      visibility: hidden;
    }
  }

  .input-number__input {
    position: absolute;
    padding-block: 0;
    left: 0;
    min-width: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: size(4);
    color: transparent;
    text-align: var(--input-text-align, start);
    box-sizing: border-box;
    @include text-truncate();

    &:disabled {
      pointer-events: none;
    }
  }

  .input-number-next--appearance-outlined {
    border: 1px solid clr(grayscale-200);
    border-radius: $border-radius;
  }
}

cad-input-number-next.input-number--focused .input-number__input,
cad-input-number-next:not(.input-number--readonly) .input-number__input:focus,
cad-input-number-next:not(.input-number--readonly):not(.input-number--with-overlay-value):hover .input-number__input {
  width: 100%;
  padding-inline: var(--padding-inline, 0);

  ~ .input-number__text {
    visibility: hidden;

    > [aria-hidden='true'] {
      display: initial;
    }

    > :not([aria-hidden='true']) {
      display: none;
    }
  }
}

cad-input-number-next:not(.input-number--readonly) .input-number__input:focus,
cad-input-number-next:not(.input-number--readonly):hover .input-number__input {
  color: clr(black);
  border-color: clr(grayscale-400);
  background-color: clr(white);
}
