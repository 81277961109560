@use 'abstracts' as *;

cad-input-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: size(8);
  box-sizing: border-box;
  max-width: 16.5rem;

  mat-datepicker-toggle {
    margin-inline-start: size(8);
  }

  cad-input-overlay-value {
    flex-grow: 1;
    width: 0;

    cad-input-overlay {
      --input-overlay-min-width: 96px;
    }
  }

  input {
    min-width: 0;

    padding-block: 0;
    height: 1.5rem;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: size(4);
    text-align: var(--input-text-align, start);
    box-sizing: border-box;

    &:hover,
    &:focus {
      border: 1px solid clr(grayscale-400);
      background: clr(white) !important;
    }

    &:disabled {
      border: none;
      background: transparent;
      color: clr(black);
    }
  }

  .mat-mdc-button-persistent-ripple,
  .mat-ripple.mat-mdc-button-ripple {
    display: none !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: size(24);
    padding: 0;
  }

  .mat-mdc-button-touch-target {
    height: size(24) !important;
    width: size(24) !important;
  }
}
