@use 'abstracts' as *;

cad-table-pagination-next {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;

  box-sizing: border-box;
  border-top: 3px solid clr(grayscale-200);
  border-bottom: 0.5px solid clr(grayscale-200);

  > :first-child {
    margin-left: auto;
  }

  .pagination__per-page {
    border: 1px solid clr(grayscale-200);
    border-radius: size(8);
    box-sizing: border-box;
    margin-right: 1rem;

    .dropdown__standalone-trigger {
      min-width: 0;
    }
  }

  .pagination__text {
    display: flex;
    align-items: center;
    padding: 0 size(8);
    column-gap: size(8);
    color: clr(grayscale-600);

    .pagination__control {
      min-width: size(16);
    }

    &--extra {
      margin-right: 0.5rem;
    }
  }

  .pagination__page {
    height: 2rem;
    width: 2rem;
    border-radius: 0.5rem;
    border: 1px solid clr(grayscale-200);
    box-sizing: border-box;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }
}
