@use 'abstracts' as *;

cad-footer-row-next {
  height: 3rem;
  width: 100%;
  box-sizing: border-box;

  line-height: 3rem;

  display: flex;
  flex-direction: row;
  flex-shrink: 0;

  overflow: hidden; // required to avoid scrollbar

  &.footer-row--without-pagination {
    .footer-cell {
      flex-grow: 1;
    }
  }

  .footer-cell {
    border-top: 3px solid clr(grayscale-200);
    border-bottom: 1px solid clr(grayscale-200);
  }

  .footer-action-cell {
    display: grid;
    place-items: center;
    width: 3rem;
    flex-shrink: 0;
  }

  .footer-action-cell--bold {
    @include font--bold();
    color: clr(grayscale-400);
  }

  .footer-button-actions {
    padding: 0 0.5rem;
    box-sizing: border-box;

    gap: 0.5rem;

    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
