@use 'abstracts' as *;

cad-table-next {
  --table-height: auto;
  --table-pagination-max-width: auto;

  width: 100%;
  height: var(--table-height);

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  transition: width 0.5s;

  &.table--footer-hidden {
    padding-bottom: 5px;
  }

  // Data container
  .data-container {
    position: relative; // Is required because of the hidden rows placeholder absolute positioning
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow-y: scroll;
    flex-grow: 1;
    width: 100%;
  }

  .data-container-fill-content {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;

    color: clr(grayscale-600);
    font-size: 1rem;
    min-height: 3rem;
  }

  .table-cell {
    height: 100%;
    width: 10rem;
    box-sizing: border-box;

    display: flex;
    align-items: center;
  }

  cad-data-cell-next,
  cad-header-cell-next {
    --border-block-color: #{clr(grayscale-200)};

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-block: 1px solid var(--border-block-color);
    border-right: $border-width solid clr(grayscale-200);
    height: 3rem;
    width: 10rem;
    box-sizing: border-box;
    flex-shrink: 0;
    padding: 0 0.5rem;

    &.without-right-border {
      border-right: none;
    }

    &.without-block-borders {
      border: none;
    }
  }

  .truncate-rows-placeholder-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0;
    background: clr(white);
    transform: translateY(-100%);
    outline: 1.5px solid clr(grayscale-200);
    z-index: 11;

    .truncate-rows-placeholder {
      color: clr(grayscale-500);
      padding-inline: size(4);
      width: auto;
    }
  }

  .truncate-rows-placeholder {
    display: flex;
    align-items: center;
    height: size(24);
    width: 0;
    overflow: hidden;
    font-style: italic;
    color: transparent;
  }

  &.table--row-height-auto {
    cad-data-cell-next,
    cad-data-row-next {
      height: auto;
      min-height: 3rem;
    }
  }

  &.table--row-align-items-start {
    cad-data-cell-next {
      align-items: flex-start;
    }
  }

  cad-header-cell-next {
    border-bottom-width: 2px;
  }

  cad-table-pagination-next {
    display: flex;
    margin-left: auto;

    container-name: footer;
    container-type: inline-size;

    @container footer (width < 450px) {
      > * {
        display: none !important;
      }
    }
  }
}
