@use 'abstracts' as *;

cad-tree-select-dropdown {
  max-width: $control-max-width;
}

.cad-tree-select-dropdown-overlay {
  .dropdown__menu-item {
    display: flex;
    align-items: center;
  }

  .tree-node-container {
    min-height: var(--option-height);
    max-width: 100%;
  }

  .tree-node-toggle-icon {
    mat-icon {
      padding: size(2);
    }
  }

  .dropdown__menu-item--selected {
    background-color: var(--selected-option-bg-color) !important;
  }

  .tree-select-dropdown-button {
    all: unset;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: size(12);
    font-style: italic;
    cursor: pointer;
  }
}
