@use 'abstracts' as *;

cad-material-password-input {
  display: block;

  mat-form-field {
    width: 100%;
  }

  .input-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: size(20);
    color: clr(grayscale-500);

    &:hover {
      color: clr(grayscale-700);
    }
  }
}
