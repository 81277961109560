@use 'abstracts' as *;

cad-input-overlay {
  --input-overlay-min-width: 130px;

  display: block;
  box-sizing: border-box;
  min-width: var(--input-overlay-min-width);

  .input-overlay-container {
    display: block;
    box-sizing: border-box;
    position: relative;
  }

  .input-overlay-control {
    width: 0;
    overflow: hidden;
    display: flex;

    > * {
      flex-grow: 1;
    }
  }

  .input-overlay-content {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .input-overlay-container--inactive {
    .input-overlay-content {
      display: none;
    }

    .input-overlay-control {
      width: unset;
    }
  }

  .input-overlay-container:not(.input-overlay-container--readonly):hover,
  .input-overlay-container--focused {
    .input-overlay-content {
      visibility: hidden;
    }

    .input-overlay-control {
      width: unset;
    }
  }
}
