@use 'abstracts' as *;

cad-dropdown {
  --trigger-element-padding-inline: #{size(8)};
  --trigger-element-padding-block: #{size(8)};
  --trigger-element-min-width: #{$control-min-width};
  --trigger-element-max-width: #{$control-max-width};
  --trigger-element-color: inherit;
  --trigger-element-bg-color: transparent;
  --trigger-element-color--hover: var(--trigger-element-color);
  --trigger-element-bg-color--hover: var(--trigger-element-bg-color);

  display: flex;
  align-items: center;
  box-sizing: border-box;

  .dropdown__standalone-trigger {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: var(--trigger-element-min-width);
    max-width: var(--trigger-element-max-width);
    padding-inline: var(--trigger-element-padding-inline);
    padding-block: var(--trigger-element-padding-block);
    color: var(--trigger-element-color);
    background-color: var(--trigger-element-bg-color);
    text-align: left;
    cursor: pointer;
    border: none;

    &:hover:not(:disabled):not(.dropdown__standalone-trigger--readonly) {
      color: var(--trigger-element-color--hover);
      background-color: var(--trigger-element-bg-color--hover);
    }

    &:disabled,
    &.dropdown__standalone-trigger--readonly {
      cursor: default;
    }

    &:disabled {
      pointer-events: none;

      cad-text-tooltip {
        pointer-events: auto;
      }

      .text {
        color: clr(grayscale-500);
      }
    }
  }

  .dropdown--appearance-outlined {
    border: 1px solid clr(grayscale-200);
    border-radius: 0.25rem;
  }

  .dropdown--size-small {
    height: size(24);
  }

  .dropdown--size-medium {
    height: size(32);
  }

  .dropdown--size-large {
    height: size(48);
  }

  .dropdown__placeholder {
    color: clr(grayscale-400);
    width: inherit;
  }

  .dropdown__value-text {
    width: inherit;
  }

  .dropdown__value-text--italic {
    font-style: italic;
  }

  .dropdown__icon {
    margin-left: size(8);
    flex-shrink: 0;

    &.disabled {
      mat-icon {
        color: clr(grayscale-400);
      }
    }

    > div {
      display: flex;
    }

    mat-icon {
      width: size(8) !important;
    }
  }

  .dropdown__icon--size-md {
    mat-icon {
      font-size: size(12) !important;
      width: size(12) !important;
      height: size(12) !important;
    }
  }
}

.cad-dropdown-overlay {
  --option-height: 40px;
  --selected-option-bg-color: #{clr(primary-50)};

  &.dropdown__menu {
    width: 100%;
    min-width: 10rem;
    max-width: 30rem;
    background-color: rgb(255, 255, 255);
    padding: 6px 0;
    margin-top: 0.5rem;

    box-shadow: 0 0 5px 0 clr(grayscale-400);
  }

  .dropdown__menu-item,
  .dropdown__menu-group {
    width: 100%;
  }

  .dropdown__scroll {
    --scrollbar-size: #{size(8)};
    --scrollbar-padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 16rem;
  }

  .dropdown__header {
    padding: 0.5rem;
    padding-bottom: 0.75rem;
    font-weight: 700;
  }

  .dropdown__spacer-top {
    border-top: 1px solid clr(grayscale-200);
  }

  .dropdown__spacer {
    border-top: 1px solid clr(grayscale-200);
  }

  .dropdown__not-found {
    padding: 0.75rem 0.5rem 0.5rem 0.5rem;
    color: clr(grayscale-400);
  }

  .dropdown__menu-item,
  .dropdown__menu-group-item {
    background-color: white;
    cursor: pointer;
    border: none;
    padding-inline: 1rem;

    user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 36px;
    min-height: var(--option-height);
    text-align: justify;
    display: block;
    align-items: flex-start;
    flex: 1;
  }

  .dropdown__menu-item--size-large {
    padding-block: size(8);
  }

  .dropdown__menu-group {
    background-color: white;
    border: none;
    text-align: left;

    input {
      flex-grow: 1;
      margin-inline: 0.5rem;
      background-color: clr(grayscale-200);
      border: none;
      padding: 0.25rem 0.25rem;
    }

    &-item {
      padding-inline: 0.5rem;
      font-weight: bold;
      cursor: default;
    }
  }

  .dropdown__menu-item:hover {
    background-color: clr(grayscale-200);
  }

  .dropdown__menu-item {
    &.dropdown__menu-item--selected {
      background-color: var(--selected-option-bg-color);
    }

    &.dropdown__menu-item--disabled {
      color: clr(grayscale-400);
      background-color: transparent;
      cursor: default;
    }

    .dropdown__menu-item-image {
      width: size(32);
      height: size(32);
      margin-right: size(8);
      flex-shrink: 0;
    }

    .dropdown__menu-item-content {
      display: flex;
      align-items: center;
    }
  }

  .dropdown__search {
    padding: 0.25rem 0rem 0.6rem 0rem;
    min-width: 10rem;
    max-width: 30rem;
    display: flex;
  }

  .dropdown__loader {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-block: 3rem;
  }

  .dropdown__footer {
    border-top: 1px solid clr(grayscale-200);
    padding-inline: size(8);
    height: size(40);
  }
}
