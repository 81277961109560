@use 'abstracts' as *;

//TODO Taras Vynnychuk 24/01/2023: refactor variables
cad-menu-item-filter-next {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: size(48);
  box-sizing: border-box;
  width: 100%;

  .menu-item-filter__icon {
    padding-right: size(8);
  }

  .menu-item-filter__conjunction {
    padding: 0 size(8);
    width: size(48);
    box-sizing: border-box;
    text-align: right;
    flex-shrink: 0;
  }

  .menu-item-filter__attribute,
  .menu-item-filter__filter-value {
    width: 10.5rem;
    margin: 0 size(8) 0 0;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .menu-item-filter__filter-value {
    padding: 0 size(4);
  }

  .menu-item-filter__filter-value-range {
    width: 5rem;
    padding: 0 size(4);
    margin: 0 size(8) 0 0;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .menu-item-filter__filter-type {
    width: 8rem;
    margin: 0 size(8) 0 0;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .menu-item-filter__active {
    padding: 0 size(16);
  }

  .menu-item-filter__filter-value,
  .menu-item-filter__filter-value-range {
    height: size(32);
  }

  .menu-item-filter__filter-value,
  .menu-item-filter__filter-value-range,
  .menu-item-filter__attribute,
  .menu-item-filter__filter-type {
    border-radius: size(4);
    border: 1px solid clr(grayscale-200);
  }

  .menu-item-filter__filter-value-container {
    flex-grow: 1;
    min-width: 12rem;
  }

  &:hover {
    .menu-item-filter__attribute,
    .menu-item-filter__filter-type,
    .menu-item-filter__filter-value,
    .menu-item-filter__filter-value-range {
      border: 1px solid clr(grayscale-400);
    }
  }

  .ignored-filter {
    margin-left: 4.5rem;
    padding: 0 size(8);

    color: clr(grayscale-500);
  }

  .menu-item-filter__filter-value-container > * {
    min-width: 12rem;
  }
}
