@use 'abstracts' as *;
cad-modal {
  .cad__modal-title {
    padding: 1.5rem;
    color: var(--color-grayscale-900);
    @include font--h1();
    padding-bottom: 1rem;
  }

  .cad__modal-text {
    padding-inline: 1.5rem;
    color: var(--color-grayscale-900);

    &:not(:last-child) {
      padding-bottom: 1.5rem;
    }
  }

  .cad__modal-btn {
    padding: 0.5rem 1.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    cad-button {
      min-width: 0;
    }
  }
}
