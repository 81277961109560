@use 'abstracts' as *;

cad-input-number-range-filter-control {
  display: flex;
  align-items: center;
  gap: 0.1rem;

  cad-input-number-next {
    width: 100%;
    max-width: 5rem;
  }
}
