@use 'abstracts' as *;

cad-header-cell-next {
  color: clr(grayscale-600);

  .header-cell__content {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;

    &.header-cell__content--sorting {
      cursor: pointer;
    }
  }
}
